<template>
  <div>
    <c-card :title="`위기관리팀(임무)`" class="cardClassDetailForm safetyOrg-org-card">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- <c-btn label="주요임무 저장" icon="save" @btnClicked="saveClick" /> -->
          <!-- <c-btn label="검색" icon="search" @btnClicked="setHighLight" /> -->
          <!-- <c-btn label="이미지 출력" icon="image" @btnClicked="() => { chartReference.exportImg({full:true}) }" />
          <c-btn label="PDF 출력" icon="picture_as_pdf" @btnClicked="exportPdf" /> -->
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div ref="org" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>

/* eslint-disable no-unused-vars */
import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';
import { jsPDF } from "jspdf";
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'crisis-management-team',
  components: {
  },
  props: {
  },
  data() {
    return {
      searchParam: {
        deptCd: null,
        userId: null,
        orgTypeCd: 'CMT'
      },
      chartReference: null,
      orgs: [],
      saveData: null,
      compact: 0,
      editable: true,
      listUrl: '',
      saveurl: '',
      checkUserUrl: '',
      saveUserUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
      userName: ''
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.eap.safetyOrg.list.url;
      this.saveurl = transactionConfig.sop.eap.safetyOrg.save.url;
      this.checkUserUrl = selectConfig.sop.eap.safetyOrg.checkUser.url;
      this.saveUserUrl = transactionConfig.sop.eap.safetyOrg.updateUser.url;
      this.deleteUserList = transactionConfig.sop.eap.safetyOrg.deleteUser.url;
      // code setting
      // list setting
      this.getList();

    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.orgs = _result.data

        this.setOrgs();
        this.setSize();
      },);
    },
    setOrgs() {
      if (!this.chartReference) {
        this.chartReference = new OrgChart();
      }
      this.chartReference
        .container(this.$refs.org) // node or css selector
        .data(this.orgs)
        .initialExpandLevel(8)
        .svgHeight(500)
        .nodeHeight((d) => 120)
        .nodeWidth((d) => {
          return 220;
        })
        .childrenMargin((d) => 150)
        .compactMarginBetween((d) => 100)
        .compactMarginPair((d) => 50)
        // .neightbourMargin((a, b) => 25)
        .siblingsMargin((d) => 100)
        .compact(false)
        // .nodeUpdate(function () {
        //   // Needed to disable default highlight behavior
        //   d3.select(this).select('.node-rect').attr('stroke', 'none');
        // })
        .linkUpdate(function (d, i, arr) {
          d3.select(this)
            .attr("stroke", "black")
            .attr("stroke-width", 4)
        })
        .nodeContent(function (d, i, arr, state) {
          let nodeContent = '';
          if (d.data.connectionFlag === 'Y') {
            nodeContent = `
            <div class="org-node node-connection">
            </div>`
          } 
          else if (d.data.level === 4) {
            nodeContent = `
            <div class="org-node cmt-node-3">
              <div class="nodeName"> ${d.data.jobName} </div>`
                    // <i aria-hidden="true" role="presentation" class="q-icon text-red notranslate material-icons">close</i>
            nodeContent += `</div>
            </div>`
          } 
          // else if (d.data.level === 4) {
          //   nodeContent = `
          //   <div class="org-node cmt-node-2">
          //     <div class="nodeName"> ${d.data.jobName} </div>
          //     <div class="q-list q-list--separator">
          //     <form>
          //       <textarea class="inputType" contents="${d.data.contents}" id="${d.data.id}"placeholder="입력하세요."> </textarea>
          //     </form>
          //     `
          //           // <i aria-hidden="true" role="presentation" class="q-icon text-red notranslate material-icons">close</i>
          //   nodeContent += `</div>
          //   </div>`
          // } 
          else {
            nodeContent = `
            <div class="org-node cmt-node-1">
              <div class="nodeName"> ${d.data.jobName} </div>`
                    // <i aria-hidden="true" role="presentation" class="q-icon text-red notranslate material-icons">close</i>
            nodeContent += `</div>
            </div>`
          }
          

          return nodeContent;
        })
        // .onNodeClick((d) => 
        //   this.openUserPop(d.data))
        .render()
        .fit();

      setTimeout(() => {
        let inputText1 = document.getElementById('SSO0000007');
        let inputText2 = document.getElementById('SSO0000008');
        let inputText3 = document.getElementById('SSO0000009');
        
        let inputThis = document.getElementsByClassName("inputType");
        

        inputThis.onkeyup = function() {
          inputThis[0].contents = inputText1.value;
          inputThis[1].contents = inputText2.value;
          inputThis[2].contents = inputText3.value;
        }
      }, 200);
    },
    openUserPop(data) {
      if (data.connectionFlag === 'Y' || data.level === 7) return;
      this.saveData = data;
      this.popupOptions.title = '사용자 검색'; // 사용자 검색
      this.popupOptions.param = {
        type: data.multipleFlag === 'N' ? 'single' : 'multiple',
        plantCd: this.$store.getters.user.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveDatas = []
        this.$_.forEach(data, _item => {
          saveDatas.push({
            // userName: _item.userName,
            userId: _item.userId,
            // deptCd: _item.deptCd,
            // deptName: _item.deptName,
            // deptUser: _item.deptName + ' / ' + _item.userName,
            id: this.saveData.id
          })
        })
        this.$http.url = this.saveUserUrl;
        this.$http.type = 'POST';
        this.$http.param = saveDatas;
        this.$http.request((_result) => {
          window.getApp.$emit("APP_REQUEST_SUCCESS");
          // this.$set(this.saveData, 'userList', saveDatas)
          this.getList();
        },);
      }
    },
    saveClick() {
      this.$http.url = this.saveurl;
      this.$http.type = 'POST';
      this.$http.param = this.orgs;
      this.$http.request((_result) => {
        window.getApp.$emit("APP_REQUEST_SUCCESS");
        // this.$set(this.saveData, 'userList', saveDatas)
        this.getList();
      },);
    },
    // setOrg(data) {
    //    window.getApp.$emit('MULTICONFIRM', {
    //     title: '확인',
    //     message: '추가하시겠습니까?<br>(두번째 버튼 클릭 시 선택되었던 사용자는 모두 삭제됩니다.)',
    //     confirmButtonText: '예',
    //     denyButtonText: '삭제',
    //     // TODO : 필요시 추가하세요.
    //     type: 'info', // success / info / warning / error
    //     // 확인 callback 함수
    //     confirmCallback: () => {
    //       this.saveData = data;
    //       this.popupOptions.title = 'LBL0000560'; // 사용자 검색
    //       this.popupOptions.param = {
    //         type: 'multiple',
    //       };
    //       this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
    //       this.popupOptions.width = '70%';
    //       this.popupOptions.visible = true;
    //       this.popupOptions.closeCallback = this.closePopup;
    //     },
    //     denyCallback: () => {
    //       this.$http.url = this.$format(this.deleteUserList, data.id);
    //       this.$http.type = 'DELETE';
    //       this.$http.request((_result) => {
    //         window.getApp.$emit("APP_REQUEST_SUCCESS");
    //         // this.saveData.userList = [];
    //         this.getList();
    //       },);
    //     },
    //     // 취소 callback 함수
    //     cancelCallback: () => {
    //     },
    //   });
    // },
    exportPdf() {
      this.chartReference.exportImg({
        save: false,
        full: true,
        onLoad: (base64) => {
          var pdf = new jsPDF();
          var img = new Image();
          img.src = base64;
          img.onload = function () {
            pdf.addImage(
              img,
              'JPEG',
              5,
              5,
              595 / 3,
              ((img.height / img.width) * 595) / 3
            );
            pdf.save('chart.pdf');
          };
        },
      });
    },
    setSize() {
      console.log(window.innerHeight - 150)
      this.chartReference.svgHeight(window.innerHeight - 150).render().fit()
    },
    setHighLight() {
      this.chartReference.clearHighlighting();
      if (!this.searchParam.userId) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '이름을 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$http.url = this.checkUserUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (_result.data) {
          this.chartReference.setHighlighted(_result.data).render();
          // this.chartReference.setUpToTheRootHighlighted(_result.data).render().fit();
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '관련된 직원이 없습니다.',
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      },);
    },
    // filtering(e) {
    //   // Get input value
    //   const value = e.srcElement.value;

    //   // Clear previous higlighting
    //   this.chartReference.clearHighlighting();

    //   // Get chart nodes
    //   const data = this.chartReference.data();

    //   // Mark all previously expanded nodes for collapse
    //   data.forEach((d) => (d._expanded = false));

    //   // Loop over data and check if input value matches any name
    //   data.forEach((d) => {
    //     if (value != '' && d.name.toLowerCase().includes(value.toLowerCase())) {
    //       // If matches, mark node as highlighted
    //       d._highlighted = true;
    //       d._expanded = true;
    //     }
    //   });
    //   // Update data and rerender graph
    //   this.chartReference.data(data).render().fit();
    // }
  },
};
</script>
<style lang="sass">
.safetyOrg-org-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important
  path
    stroke-width: 2 !important
    stroke: #B4B4B8 !important

.org-node
  margin-top:-1px
  margin-left:-1px
  position: absolute !important

.cmt-node-1
  border-radius:10px
  border: 1px solid #B4B4B8
  background-color: white
  width:250px
  min-height:100px
  height: 100%
  top: 20px
  right: -10px
  .nodeName
    font-weight: 800
    font-size: 22px
    color: #fff
    padding-bottom: 10px
    padding-top: 27px
    text-align: center
    background: #0D9276
    border-radius: 5px
    height: 100%
  .subName
    color:#08011E
    margin-left:60px
    margin-top:3px
    font-size:15px
    font-weight: 500
  .q-item
    font-size: 17px
    font-weight: 600
    .q-icon
      font-size: 33px

.cmt-node-2
  border-radius:10px
  border: 1px solid #B4B4B8
  background-color: white
  width:250px
  min-height:100px
  height:150px
  top: 20px
  right: -10px
  .nodeName
    font-weight: 800
    font-size: 22px
    color: #fff
    padding-bottom: 10px
    padding-top: 5px
    text-align: center
    background: #0D9276
    border-radius: 5px
  .inputType
    width: 100%
    height: 97px
    margin-top: 3px
    border-radius: 5px
    border: 1px solid
    font-size: 15pt
    resize: vertical
  .subName
    color:#08011E
    margin-left:90px
    margin-top:3px
    font-size:15px
    font-weight: 500
  .q-item
    font-size: 17px
    font-weight: 600
    .q-icon
      font-size: 33px
    
.cmt-node-3
  border-radius:10px
  border: 1px solid #B4B4B8
  background-color: white
  width:250px
  min-height:100px
  height: 100%
  top: 20px
  right: -10px
  .nodeName
    font-weight: 800
    font-size: 22px
    color: #000000
    padding-bottom: 10px
    padding-top: 27px
    text-align: center
    background: #ffffff
    border-radius: 5px
    height: 100%
  .subName
    color:#08011E
    margin-left:60px
    margin-top:3px
    font-size:15px
    font-weight: 500
  .q-item
    font-size: 17px
    font-weight: 600
    .q-icon
      font-size: 33px  

.node-connection
  border: 1px solid #B4B4B8
  left:110px
  top:-20px
  width:0px
  height:130px
</style>
<style>
.safetyOrgBtn {
  box-sizing: border-box;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  /* padding: 5px 11px; */
  margin: 0;
  cursor: pointer;
  background-color: #dfdddd;
  outline: none;
  border: 0px solid rgba(0,0,0,.1);
  border-radius: 8px;
  color: #7c7b7b;
  white-space: nowrap;
}

.safetyOrgBtn:hover {
  background-color: #8b8a8a;
  color: #fff;
}
</style>